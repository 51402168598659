<template>
    <div style="height: 600px;">
        <div style="line-height: 35px;margin-left: 20px;">结算账户余额：<span style="color:#F69403;">{{accountBalance.acct_balance}}</span><span style="color: #2FB8FD;"> (可用余额 + 冻结余额)</span></div>
        <div style="line-height: 35px;margin-left: 20px;">可用余额：<span style="color:#F69403;">{{accountBalance.avl_balance}}</span><span style="color: #2FB8FD;"> (该余额大于0时，才可发起取现)</span></div>
        <div style="line-height: 35px;margin-left: 20px;">冻结余额：<span style="color:#F69403;">{{accountBalance.frz_balance}}</span><span style="color: #2FB8FD;"> (当配置了自动结算功能，在每日发起结算时会将可用余额转为冻结金额。该部分金额不允许取现)</span></div>
        <div style="line-height: 35px;margin-left: 20px;">昨日日终余额:<span style="color:#F69403;">{{accountBalance.last_avl_balance}}</span></div>
        <div style="line-height: 35px;margin-left: 20px;">当前结算的银行卡:<a href="#" @click="dialogVisible = true">{{settleAccount.bankName}}</a><span style="color:#F69403;"></span> <span @click="replaceSettlement" style="color: #FF9800;cursor: pointer;;">更换</span></div>
        <div style="margin: 20px 0 20px 20px;">
            <el-button @click="a('/test/unionPay/adapay_pay')">发起支付</el-button>
            <el-button @click="starttransferMoney">发起转账</el-button>
            <el-button @click="starttransFer">发起分账</el-button>
            <el-button @click="startCash">发起提现</el-button>
        </div>

        <!--        发起转账-->
        <div v-if="transferMoney == true">
            <div  style="cursor: pointer;width: 580px;color: #3d97d4;line-height: 50px;text-align: center;margin-left: 20px;">
                <div style="float: left;">
                    <el-input type="" placeholder="输入对方手机号" v-model="mobile" clearable></el-input>
                </div>
                <div style="float: left;margin-left: 20px;">
                    <el-button @click="checkedMoile">搜索</el-button>
                </div>
            </div>
            <div  style="float: left;width: 100%;margin-left: 20px;">
                <template>
                    <el-radio-group v-model="radio" @change="agreeChange">
                        <el-radio v-for="(item,index) in user" :key="index" :label="item.uuid">{{item.nickName}}</el-radio>
                    </el-radio-group>
                    <div v-if="user.length > 0" style="padding-top: 20px;">
                        <span style="color: #F89D56;">昵称:</span>{{account.nickName}}
                        <span style="color: #F89D56;">真实姓名:</span>{{account.realName}}
                        <span style="color: #F89D56;">账户名称:</span>{{account.bankName}}
                    </div>
                </template>
            </div>
            <div  style="cursor: pointer;width: 260px;color: #3d97d4;line-height: 50px;text-align: center;margin-left: 20px;">
                <el-input type="number" placeholder="输入金额" v-model="payAmt" clearable></el-input>
            </div>
            <div style="width: 500px;height: 40px;margin-left: 20px;margin-top: 10px;">
                <el-input style="width: 150px;" v-model="code" placeholder="请输入验证码"></el-input>
                <el-button @click="codeTransfer" size="mini" round>发送验证码</el-button>
            </div>
            <div style="margin-left: 20px;margin-top: 20px;">
                <el-button  type="primary" @click="settleAccountTransferDetermine">确定转账</el-button>
            </div>
        </div>

        <!--        发起分账-->
        <div v-if="transFer == true">
            <div  style="cursor: pointer;width: 260px;color: #3d97d4;line-height: 50px;text-align: center;margin-left: 20px;">
                <el-input type="number" placeholder="输入金额" v-model="payAmt" clearable></el-input>
            </div>
            <div style="width: 500px;height: 40px;margin-left: 20px;margin-top: 10px;">
                <el-input style="width: 150px;" v-model="code" placeholder="请输入验证码"></el-input>
                <el-button @click="codeTransferMoney" size="mini" round>发送验证码</el-button>
            </div>
            <div style="margin-left: 20px;margin-top: 20px;">
                <el-button  type="primary" @click="settleAccountPennyDetermine">确定分账</el-button>
            </div>
        </div>

        <!--        发起提现-->
        <div v-if="showCash == true">
            <div  style="cursor: pointer;width: 260px;color: #3d97d4;line-height: 50px;text-align: center;margin-left: 20px;">
                <el-input type="number" placeholder="输入金额" v-model="payAmt" clearable></el-input>
            </div>
            <div style="margin-left: 20px;margin-top: 20px;">
                <el-button  type="primary" @click="alpplyBankCarCash">确定提现</el-button>
            </div>
        </div>

<!--        结算账户详情-->
        <div>
            <el-dialog :close-on-click-modal="false"
                    title="提示"
                    :visible.sync="dialogVisible"
                    width="30%"
                    :before-close="handleClose">
                <span>
                    <div style="line-height: 35px;margin-left: 20px;">所属银行:<span style="color:#F69403;">{{settleAccount.bankName}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">卡号:<span style="color:#F69403;">{{settleAccount.cardId}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">预留手机:<span style="color:#F69403;">{{settleAccount.telNo}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">绑定时间:<span style="color:#F69403;">{{settleAccount.updateTime}}</span></div>
                </span>
                <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
              </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        name: "bank_cash",
        data(){
            return{
                accountBalance:[],
                settleAccount:{},
                dialogVisible: false,
                payAmt:'',
                code:'',
                orderNo:'',
                //显示隐藏
                transFer:false,
                showCash:false,
                transferMoney:false,

                mobile:"",
                user:"",
                radio: '',
                account:{}


            }
        },
        mounted() {
            this.queryAccountBalance();
            this.querySettleAccount();
            this.querySettleAccountUserList();


        },
        methods:{
            //获取账户余额
            queryAccountBalance(){
                var that = this;
                var params = {};
                that.newApi.queryAccountBalance(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.accountBalance = res.data;
                    }
                })
            },
            //获取绑定的结算卡
            querySettleAccount(){
                var that = this;
                var params = {};
                that.newApi.querySettleAccount(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.settleAccount = res.data;
                    }
                })
            },
            handleClose(done) {
                this.$confirm('确认关闭？').then(res => {
                    console.log(res)
                    done();
                })
            },

            //更换结算卡
            replaceSettlement(){
                //先删除在重新绑 https://docs.adapay.tech/api/trade.html#id50
            },

            //转账表单显示/隐藏
            starttransferMoney(){
                this.showCash = false;
                this.transFer = false;
                this.transferMoney = !this.transferMoney;
            },
            //手机号搜索用户
            checkedMoile(){
                var that = this;
                var params = {};
                params.phone = this.mobile;
                that.newApi.checkedMoile(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.user = res.data;
                    }
                })
            },
            //勾选转账人员
            agreeChange:function(val){
                var that = this;
                for (let i = 0;i < that.user.length;i++){
                    if(val == that.user[i].uuid){
                        let obj = {};
                        obj.uuid = that.user[i].uuid;
                        obj.nickName = that.user[i].nickName;
                        obj.bankName = that.user[i].bankName;
                        obj.realName = that.user[i].realName;
                        that.account = obj;
                    }
                }
            },

            //发送转账验证码
            codeTransfer(){
                var that = this;
                var params = {};
                params.payAmt = that.utils.keepTwoDecimalFull(that.payAmt);
                params.uuid = that.account.uuid;
                that.newApi.settleAccountTransfer(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus("验证码发送成功");
                        that.orderNo = res.data;
                    }
                })
            },

            //转账按钮
            settleAccountTransferDetermine(){
                var that = this;
                var params = {};
                params.orderNo = that.orderNo;
                params.code = that.code;
                that.newApi.settleAccountTransferDetermine(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus(res.data);
                        that.mobile = "";
                        that.payAmt = "";
                        that.code ="";
                    }
                })
            },

            //分账表单显示/隐藏
            starttransFer(){
                this.showCash = false;
                this.transferMoney = false;
                this.transFer = !this.transFer;
            },
            //获取所有绑定结算账户列表
            querySettleAccountUserList(){
                var that = this;
                var params = {};
                that.newApi.querySettleAccountUserList(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.settleAccountUserList = res.data;
                        console.log(that.settleAccountUserList);
                    }
                })
            },

            //发送分账验证码
            codeTransferMoney(){
                var that = this;
                var params = {};
                params.payAmt = that.utils.keepTwoDecimalFull(that.payAmt);
                params.applyId = that.settleAccount.applyId;
                that.newApi.settleAccountPenny(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus("验证码发送成功");
                        that.orderNo = res.data;
                    }
                })
            },
            //分账按钮
            settleAccountPennyDetermine(){
                var that = this;
                var params = {};
                params.code = that.code;
                params.orderNo = that.orderNo;
                that.newApi.settleAccountPennyDetermine(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus(res.data);
                        that.orderNo = res.data;
                        that.code = "";
                        that.payAmt = "";
                    }
                })
            },

            //提现表单显示/隐藏
            startCash(){
                this.transFer = false;
                this.showCash = !this.showCash;
            },
            //提现按钮
            alpplyBankCarCash(){
                var that = this;
                var params = {};
                params.cashAmt = that.payAmt;
                that.newApi.alpplyBankCarCash(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus(res.data);
                        that.payAmt = "";
                    }
                })
            },

        }
    }
</script>

<style scoped>

</style>
